<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The same extract mixture was spotted on
        <a
          href="https://canvas.instructure.com/courses/9870393/files/262636955/download?download_frd=1"
          >three TLC plates</a
        >
        and then each plate was developed in a different eluent mixture.
      </p>

      <p class="mb-4 pl-6">
        <v-img style="max-width: 500px" src="/img/assignments/UCIrvine/threeTLCPlates.png" />
      </p>

      <p class="mb-2">
        Which plate demonstrates the best separation of the mixture?

        <v-select
          v-model="inputs.input1"
          style="max-width: 250px"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Which eluent mixture was the most polar?

        <v-select
          v-model="inputs.input2"
          style="max-width: 250px"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Which eluent mixture has an intermediate polarity (relative to the polarity of the other two
        mixtures)?

        <v-select
          v-model="inputs.input3"
          style="max-width: 250px"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ3S2Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      items: [
        {text: '$\\#1$', value: 'one'},
        {text: '$\\#2$', value: 'two'},
        {text: '$\\#3$', value: 'three'},
      ],
    };
  },
};
</script>
<style scoped></style>
