var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v(" The same extract mixture was spotted on "),_c('a',{attrs:{"href":"https://canvas.instructure.com/courses/9870393/files/262636955/download?download_frd=1"}},[_vm._v("three TLC plates")]),_vm._v(" and then each plate was developed in a different eluent mixture. ")]),_c('p',{staticClass:"mb-4 pl-6"},[_c('v-img',{staticStyle:{"max-width":"500px"},attrs:{"src":"/img/assignments/UCIrvine/threeTLCPlates.png"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Which plate demonstrates the best separation of the mixture? "),_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Which eluent mixture was the most polar? "),_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Which eluent mixture has an intermediate polarity (relative to the polarity of the other two mixtures)? "),_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }